// Main js
// There are some inline scripts that should be moved to this module
function ready(fn) {
    if (document.readyState !== "loading") {
        // jquery should be available
        fn();
    }
    else {
        document.addEventListener("DOMContentLoaded", fn);
    }
}
function pageLoadReady() {
    // Centralized DOM ready calls
    // TODO: create array for sub elements to add ready functions
    function intializeMainNav() {
        // global nav and it's sub menus, search input
        var globalClickListener = function (event) {
            // global, persistent touch/click
            // Main nav: test click outside menu, dismiss menu or ignore click
            var openMenus = document.querySelectorAll('.has-submenu.open');
            openMenus.forEach(function (openMenu) {
                var target = event.target;
                if (!openMenu.contains(target)) {
                    hideSubmenu(openMenu);
                }
            });
        };
        // global, persistent touch/click events
        document.addEventListener('click', globalClickListener);
        document.addEventListener('touchend', globalClickListener);
        // Main nav submenu functions
        function showSubmenu(submenu) {
            var aElem = submenu.querySelector('a.submenu-label');
            aElem.parentElement.classList.add('open');
            aElem.parentElement.classList.remove('closed');
            aElem.setAttribute('aria-expanded', "true");
        }
        function hideSubmenu(menu) {
            var aElem = menu.querySelector('a.submenu-label');
            aElem.parentElement.classList.add('closed');
            aElem.parentElement.classList.remove('open');
            aElem.setAttribute('aria-expanded', "false");
        }
        // Submenus click event
        var menuItems = document.querySelectorAll('#nav-menu li.has-submenu');
        menuItems.forEach.call(menuItems, function (el, i) {
            el.querySelector('a').addEventListener("click", function (event) {
                // anchors used to preserve accessibility. Use aria-haspopup, aria-expanded
                event.preventDefault();
                if (this.parentElement.classList.contains('closed')) {
                    showSubmenu(this.parentElement);
                    return false;
                }
                hideSubmenu(this.parentElement);
                return false;
            });
        });
        // menu for small screen
        document.querySelector('header #menu-button').addEventListener('click', toggleSmMenu);
        //document.querySelector('header #menu-button').addEventListener('touchend', toggleSmMenu); 
        function toggleSmMenu(event) {
            var nav = document.querySelector('header nav');
            if (nav.classList.contains('open')) {
                nav.classList.remove('open');
                nav.classList.add('closed');
                nav.setAttribute('aria-expanded', "false");
            }
            else {
                nav.classList.add('open');
                nav.classList.remove('closed');
            }
        }
        // Search input toggle
        function toggleSearchFly() {
            var elem = document.getElementById('large-menu-search');
            if (elem.classList.contains('open')) {
                elem.classList.remove('open');
            }
            else {
                elem.classList.add('open');
            }
        }
        document.querySelector('#large-menu-search a').addEventListener("click", function (event) {
            event.preventDefault();
            toggleSearchFly();
        });
    }
    intializeMainNav();
}
ready(pageLoadReady);
